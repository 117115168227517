<template>

  <b-container class="body-container">
    <h1>Minor Children</h1>
    <p>You can resign your minor children using QuitMormon.com.</p>
    <div>
      <h4>What if one parent isn't resigning?</h4>
      <p>This is fine, and something we deal with all the time.</p>
      <ul>
        <li>Create a resignation profile for the child and specify the names of both parents</li>
        <li>Each parent must sign</li>
      </ul>
    </div>

    <div>
      <h4>Do I have to select both parents?</h4>
      <p>In most cases, yes. Both parents will have to sign and approve for the children's records to be
        removed.&nbsp;</p>
    </div>

    <div>
      <h4>I have full custody. Do I still have to select both parents?</h4>
      <p>
        If your custody agreement has specific wording stating that you have the ability to make religious decisions for
        your children, then it is OK to just select one parent.
      </p>
    </div>

    <div>
      <h4>The other parent is deceased</h4>
      <p>If the other parent has passed away, please select "The other parent's signature is not required" when creating
        the resignation for your child. Please upload a copy of the death certificate with the child's signed and
        notarized resignation to ensure it is processed without delay.</p>
    </div>
    <div>
      <h4>Do children need to sign?</h4>
      <p>
        Children / minors do not need to sign. In our updated form, we've removed the line where children would
        normally sign. It wasn't needed and made the process more complicated.
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'MinorChildren',
};
</script>

<style scoped>

</style>
